import { render, staticRenderFns } from "./AgreenForm.vue?vue&type=template&id=1d0a38d7&scoped=true"
import script from "./AgreenForm.vue?vue&type=script&lang=js"
export * from "./AgreenForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d0a38d7",
  null
  
)

export default component.exports